import React from "react"
import styles from "./development.module.scss"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import workExperience from "../data/workExperience"

const animations = {
  // dev card container animation states
  container: {
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.25,
        staggerChildren: 0.2,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        delay: 0,
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
  },

  // dev card animation states
  card: {
    visible: { scale: 1, opacity: 1 },
    hidden: { scale: 0.1, opacity: 0 },
  },
}

const DevelopmentPage = () => {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={animations.container}
      key="development"
    >
      <h1 className={styles.pageTitle}>Projects</h1>

      <div className={styles.devCardContainer}>
        {workExperience.map((project, index) => (
          <motion.div
            key={index}
            variants={animations.card}
            className={styles.devCard}
          >
            <div className={styles.headings}>
              <h3 className={styles.devCardHeading}>{project.title}</h3>
              <h4 className={styles.devCardSubheading}>{project.years}</h4>
            </div>

            <div className={styles.infoSection}>
              <p className={styles.devCardDesc}>{project.cardDescription}</p>

              {(project.viewDetailsLink || project.viewSourceGitLink) && (
                <div className={styles.devCardBtns}>
                  {project.viewDetailsLink && (
                    <Link
                      className={styles.devCardLinks}
                      to={project.viewDetailsLink}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.devCardICO}
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />
                        <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />
                      </svg>
                      View details
                    </Link>
                  )}

                  {project.viewSourceGitLink && (
                    <a
                      className={styles.devCardLinks}
                      title="Link to Github"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={project.viewSourceGitLink}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.devCardICO}
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path d="M9 19c-4.286 1.35-4.286-2.55-6-3m12 5v-3.5c0-1 .099-1.405-.5-2 2.791-.3 5.5-1.366 5.5-6.04a4.567 4.567 0 0 0 -1.333 -3.21 4.192 4.192 0 00-.08-3.227s-1.05-.3-3.476 1.267a12.334 12.334 0 0 0 -6.222 0C6.462 2.723 5.413 3.023 5.413 3.023a4.192 4.192 0 0 0 -.08 3.227A4.566 4.566 0 004 9.486c0 4.64 2.709 5.68 5.5 6.014-.591.589-.56 1.183-.5 2V21" />
                      </svg>
                      View source
                    </a>
                  )}
                </div>
              )}
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  )
}

export default DevelopmentPage
