const workExperience = [
  {
    title: "Self-Serve Restaurant Kiosk",
    years: "2022",
    cardDescription:
      "Customer-facing self-serve kiosk powered by Shopify Hydrogen",
    viewDetailsLink: "/work/Tacorrido",
    viewSourceGitLink: "https://github.com/isaacd8k/tacorrido-kiosk",
  },
  {
    title: "Orders/Subscriptions Web App",
    years: "2022",
    cardDescription:
      "Full stack order & subscription manager using AWS AppSync and NextJS (in development)",
    viewDetailsLink: "/work/PubSubManager",
    viewSourceGitLink: "https://github.com/isaacd8k/subs-delivery-manager",
  },
  {
    title: "Kiosk Web App",
    years: "2021",
    cardDescription:
      "Internationalized information kiosk built on a headless CMS",
    viewDetailsLink: "/work/KioskVenueProject",
    viewSourceGitLink: "https://github.com/isaacd8k/nextjs-demo",
  },
  {
    title: "Louis Martin Jewelers",
    years: "2017-2020",
    cardDescription:
      "Shopify Admin app developer for the product management team and frontend developer of customer-facing website",
    viewDetailsLink: "/work/LMJ",
    viewSourceGitLink: "",
  },
  {
    title: "WT",
    years: "2020-2022",
    cardDescription:
      "UX Support / Dev liaison for in house accounting application",
    viewDetailsLink: "",
    viewSourceGitLink: "",
  },
]

export default workExperience
